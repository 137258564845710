import * as firebase from "firebase/app";
import "firebase/messaging";

const initializedFirebaseApp = firebase.initializeApp({
    apiKey: "AIzaSyCYPRZKEdAjRjRJWn7C6RcIMH9Q2XJWMW8",
    authDomain: "pikkart-remote-assistance.firebaseapp.com",
    databaseURL: "https://pikkart-remote-assistance.firebaseio.com",
    projectId: "pikkart-remote-assistance",
    storageBucket: "pikkart-remote-assistance.appspot.com",
    messagingSenderId: "19073895783",
    appId: "1:19073895783:web:0fdf94a79328b3538073c2",
    measurementId: "G-10SZ9N3CRH"
  });

export { initializedFirebaseApp };