import { connect } from "react-redux";
import React from "react";
import { f7 as f7React } from "framework7-react";
import * as actions from "../../redux/actions";
import localeStrings from "./notifications-alert-local";

import { RaApiAuth } from "../../ra-lib/api/auth";
import { RaNotifications } from "../../ra-lib/notifications";

export class NotificationsAlert extends React.Component {
  render() {
    return null;
  }

  checkServiceRegistration = () => {
    const self = this;
    RaNotifications.checkServiceRegistration()
      .then(function (successResult) {
        if (successResult === true) {
        } else {
          const errorMsg = localeStrings.notification_error;
          self.props.setNotificationServiceError(errorMsg);
        }
      })
      .catch(function (errorMessage) {
        //handle error
        self.props.setNotificationServiceError(errorMessage);
      });
  };

  componentDidMount = () => {
    if (RaApiAuth.isAuthenticated()) {
      this.checkServiceRegistration();
    }
  };

  componentDidUpdate = (prevProps, prevState, snapshot) => {
    if (
      prevProps.notificationServiceError !==
      this.props.notificationServiceError &&
      this.props.notificationServiceError &&
      RaApiAuth.isAuthenticated()
    ) {
      console.error(this.props.notificationServiceError);

      const self = this;
      const app = f7React;
      // With callback on close
      var notificationCallbackOnClose = app.notification.create({
        icon: '<i class="material-icons">warning</i>',
        title: localeStrings.notification_title,
        titleRightText: localeStrings.notification_now,
        subtitle: localeStrings.notification_error,
        text: localeStrings.notification_retry,
        closeOnClick: true,
        on: {
          closed: function () {
            setTimeout(() => {
              self.props.setNotificationServiceError(null);
              self.checkServiceRegistration();
            }, 1000);
          },
        },
      });

      // Open Notifications
      notificationCallbackOnClose.open();
    }
  };
}

// #region Redux
const mapStateToProps = (state) => {
  return {
    notificationServiceError: state.app.notificationServiceError,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setNotificationServiceError: (notificationServiceError) =>
      dispatch(actions.setNotificationServiceError(notificationServiceError)),
  };
};

// #endregion

export default connect(mapStateToProps, mapDispatchToProps)(NotificationsAlert);
