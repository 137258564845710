import React from "react";
import { connect } from "react-redux";
import { Icon, Fab, Page, Block, Chip } from "framework7-react";
import localeStrings from "./incomingcall-local";

import { RaStorage } from "../../ra-lib/storage";
import { RaApiCalls } from "../../ra-lib/api/calls/calls";
import Commons from "../../Commons";

class IncomingCall extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    let loginCompany = RaStorage.getLoginCompany();
    let logoImg = null;
    logoImg = (
      <img
        src={
          loginCompany && loginCompany.LandingLogoUrl
            ? loginCompany.LandingLogoUrl
            : "./img/landing-image.png"
        }
        alt="landing"
        style={{ width: "40%", maxWidth: "172px", maxHeight: "172px" }}
      ></img>
    );

    let paymentMinuteLeft = null;
    if (
      RaApiCalls.getIncomingCaller() &&
      RaApiCalls.getIncomingCaller().PaidService
    ) {
      const minuteLeft =
        RaApiCalls.getIncomingCaller().PurchasedMinutes -
        RaApiCalls.getIncomingCaller().ServiceUsageMinutes;
      paymentMinuteLeft = (
        <Chip
          outline
          color="blue"
          text={minuteLeft + " " + localeStrings.message_minutes_left}
        />
      );
    }

    return (
      <Page colorTheme="custom">
        <Block
          className="call-block"
          strong
          style={{ textAlign: "center", padding: "10%" }}
        >
          {logoImg}
          <h1>
            {RaApiCalls.getIncomingCaller() != null
              ? RaApiCalls.getIncomingCaller().Name
              : ""}
          </h1>
          <div>
            {RaApiCalls.getIncomingCaller() &&
              RaApiCalls.getIncomingCaller().Company != null
              ? RaApiCalls.getIncomingCaller().Company.Name +
              (RaApiCalls.getIncomingCaller() != null &&
                RaApiCalls.getIncomingCaller().CompanyRole
                ? " - "
                : "")
              : ""}
            {RaApiCalls.getIncomingCaller() != null
              ? RaApiCalls.getIncomingCaller().CompanyRole
              : ""}
          </div>
          <br></br>
          <div>
            <Icon
              ios="f7:videocam"
              aurora="f7:videocam"
              md="material:videocam"
            ></Icon>
            &nbsp;<i>{localeStrings.incoming_call_text}</i>
          </div>
          <br></br>
          {paymentMinuteLeft}
        </Block>
        <Fab
          onClick={(e) => {
            this.onDeclinedCall();
          }}
          position="left-bottom"
          slot="fixed"
          text={localeStrings.call_decline}
          color="red"
        >
          <Icon
            ios="f7:phone_down_fill"
            aurora="f7:phone_down_fill"
            md="material:call_end"
          ></Icon>
        </Fab>

        <Fab
          onClick={(e) => {
            this.onAcceptedCall();
          }}
          position="right-bottom"
          slot="fixed"
          style={{ position: "fixed" }}
          text={localeStrings.call_accept}
          color="green"
        >
          <Icon
            ios="f7:phone_fill"
            aurora="f7:phone_fill"
            md="material:call"
          ></Icon>
        </Fab>
      </Page>
    );
  }

  getRouter = () => {
    if (this.props.router) {
      return this.props.router;
    } else {
      return this.props.f7router;
    }
  };

  onAcceptedCall = () => {
    Commons.acceptedCall(this.getRouter());
  };

  onDeclinedCall = () => {
    Commons.declinedCall(this.getRouter(), this.props.isPopup);
  };

  componentDidMount = () => {
  };

  componentDidUpdate = (prevProps, prevState, snapshot) => {
    const self = this;
    if (!self.props.isPopup && self.props.incomingCall === null) {
      self.getRouter().back("/main/");
    }
  };
}

// #region Redux
const mapStateToProps = (state) => {
  return {
    incomingCall: state.remoteAssistance.incomingCall,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

// #endregion

export default connect(mapStateToProps, mapDispatchToProps)(IncomingCall);
