import { RaLog } from "../log";
import { RaCordovaPermissions } from "../cordova/permissions";
import { RaNotifications } from "../notifications";
import { Ra } from "../index";
import { RaVoipNotifications } from "../voip-notifications";

export class RaCordova {
  static isCordova = () => {
    if (window.cordova && window.cordova.version) {
      //RaLog.log("we are in cordova");
      return true;
    }
    //RaLog.log("we are NOT in cordova");
    return false;
  };

  static initCordova = () => {
    return new Promise((resolve, reject) => {
      RaNotifications.init_cordova_notifications();

      if (Ra.getConfig().callsEnabled) {
        RaCordovaPermissions.askCallPermissions()
          .then((response) => {
            RaLog.log(response);
          })
          .catch((e) => {
            RaLog.log(e);
          })
          .finally(() => {
            resolve();
            //}
          });
      } else {
        resolve();
      }
    });
  };

  static getAppOpenerMessage = () => {
    return new Promise((resolve, reject) => {
      function error(errorMessage) {
        reject(errorMessage);
      }

      function success(message) {
        if (message) {
          resolve(message);
        } else {
          resolve(null);
        }
      }

      const notificationManager = window.cordova.plugins.NotificationManager;
      if (notificationManager) {
        window.cordova.plugins.NotificationManager.getLatestMessage(
          success,
          error
        );
      } else {
        error("plugin not found");
      }
    });
  };

  static getDeviceInfo = () => {
    return new Promise((resolve, reject) => {
      function error(errorMessage) {
        reject(errorMessage);
      }

      function success(deviceInfo) {
        if (deviceInfo) {
          resolve(deviceInfo);
        } else {
          reject("error retrieving device info");
        }
      }

      var permissions = window.cordova.plugins.permissions;
      permissions.getDeviceInfo(success, error);
    });
  };
}
export default RaCordova;
